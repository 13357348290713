// COMPONENTS
import React from 'react'
import Page from 'templates/Page'
// CONTENT
import content from 'content/readme.yml'

// Readme page
const ReadmePage = () => {
  return <Page content={content} />
}

export default ReadmePage
